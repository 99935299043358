import React from "react"

import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Form from "../components/Form"
import Seo from "../components/Seo"


const typesToFieldNames = {
    "Rapid PCR": "pcrTest",
    "Lab based PCR": "labTest",
    "Rapid Machine Antigen": "rmaTest",
    "sanitize": "sanitize",
    "Personal Protective Equipment": "ppe",
    "Hire a Testing Coordinator": "ccofficer"
}

const RatesPage = (props) => {
    const pageData = props.data.wpPage
    const seoData = props.data.wpPage.seo
    return (
        <Layout extraClass="rates" pageId={pageData.databaseId}>
            <Seo
                title={seoData.title}
                description={seoData.metaDesc}
                canonical={seoData.canonical}
                keywords={seoData.metaKeywords}
            />
            <section>
                <div className="container">
                    <div className="form">
                        <div className="thanks">
                            Thanks! We'll be in touch!
                        </div>
                        <h1>Let's Work Together</h1>
                        <p className="intro-text">
                            {pageData.copy}
                        </p>
                        <Form precheckedValue={typesToFieldNames[props.location?.state?.type]} />
                    </div>
                    <div className="sub-message">
                        <h4>{pageData.requestRates.submessage1.headline}</h4>
                        <span><a href={`tel:${pageData.requestRates.submessage1.phoneNumber}`}>{pageData.requestRates.submessage1.phoneNumber}</a></span>
                        <span><a href={`mailto:${pageData.requestRates.submessage1.emailAddress}`}>{pageData.requestRates.submessage1.emailAddress}</a></span>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default RatesPage

export const ratesQuery = graphql`
    query ratespageQuery {
        wpPage(databaseId: { eq: 37 }) {
            databaseId
            seo {
                title
                canonical
                metaDesc
                metaKeywords
            }
            title
            requestRates {
                submessage1 {
                    emailAddress
                    phoneNumber
                    headline
                  }
              }
        }
    }
`