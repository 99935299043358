import React from "react"
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact({precheckedValue}) {
  const [state, setState] = React.useState(precheckedValue ? { [precheckedValue]: true} : {})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleCheckboxChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <form
      name="contact"
      method="post"
      action="/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <div class="row">
        <div className="grid-6">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" required onChange={handleChange} placeholder="Name" />
        </div>
        <div className="grid-6">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" required onChange={handleChange} placeholder="Email" />
        </div>
        <div className="grid-6">
          <label htmlFor="phone">Phone</label>
          <input type="text" name="phone" required onChange={handleChange} placeholder="Phone" />
        </div>
        <div className="grid-6">
          <label htmlFor="production">Production Name</label>
          <input type="text" name="production" onChange={handleChange} placeholder="Production Name" />
        </div>
      </div>
      <h4>Services that interest you:</h4>
      <ul>
        <li>
          <input type="checkbox" id="pcrTest" name="pcrTest" checked={!!state.pcrTest} value="pcrTest" onChange={handleCheckboxChange} />
          <label htmlFor="pcrTest">Rapid PCR Testing</label>
        </li>
        <li>
          <input type="checkbox" id="labTest" name="labTest" checked={!!state.labTest} value="labTest" onChange={handleCheckboxChange} />
          <label htmlFor="labTest">Lab-based PCR Testing</label>
        </li>
        <li>
          <input type="checkbox" id="rmaTest" name="rmaTest" checked={!!state.rmaTest} value="rmaTest" onChange={handleCheckboxChange} />
          <label htmlFor="rmaTest">Rapid Machine Antigen</label>
        </li>
        <li>
          <input type="checkbox" id="ccofficer" name="ccofficer" checked={!!state.ccofficer} value="ccofficer" onChange={handleCheckboxChange} />
          <label htmlFor="ccofficer">Covid Testing Coordinator</label>
        </li>
        <li>
          <input type="checkbox" id="sanitize" name="sanitize" checked={!!state.sanitize} value="sanitize" onChange={handleCheckboxChange} />
          <label htmlFor="sanitize">Professional Sanitization</label>
        </li>
        <li>
          <input type="checkbox" id="ppe" name="ppe" checked={!!state.ppe} value="ppe" onChange={handleCheckboxChange} />
          <label htmlFor="ppe">Personal Protective Equipment</label>
        </li>
        <li>
          <input type="checkbox" id="medic" name="medic" checked={!!state.medic} value="medic" onChange={handleCheckboxChange} />
          <label htmlFor="medic">Set Medic</label>
        </li>
        <li>
          <input type="checkbox" id="scuba" name="scuba" checked={!!state.scuba} value="scuba" onChange={handleCheckboxChange} />
          <label htmlFor="scuba">Scuba Rescue Safety Diver</label>
        </li>
        <li>
          <input type="checkbox" id="lifeguard" name="lifeguard" checked={!!state.lifeguard} value="lifeguard" onChange={handleCheckboxChange} />
          <label htmlFor="scuba">Lifeguard</label>
        </li>
        <li>
          <input type="checkbox" id="supervisor" name="supervisor" checked={!!state.supervisor} value="supervisor" onChange={handleCheckboxChange} />
          <label htmlFor="supervisor">Covid-19 Compliance Supervisor</label>
        </li>
        <li>
          <input type="checkbox" id="event" name="event" checked={!!state.event} value="event" onChange={handleCheckboxChange} />
          <label htmlFor="event">Event Medical Coordinator</label>
        </li>
        <li>
          <input type="checkbox" id="advisor" name="advisor" checked={!!state.advisor} value="advisor" onChange={handleCheckboxChange} />
          <label htmlFor="advisor">Healthcare Advisor</label>
        </li>
      </ul>
      <div className="grid-12">
        <label htmlFor="message">Message</label>
        <textarea name="message" onChange={handleChange} />
      </div>
      <button type="submit" className="btn red">Send</button>
    </form>
  )
}